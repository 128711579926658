<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :show-close="false"
    width="28%"
    @close="cancelBind"
  >
  <div style="text-align:center">
      <div style="margin: 0 auto; display: inline-block; text-align:left; padding-bottom:5%;">
      <div style="font-size: 24px">绑定微信</div>
      <el-divider></el-divider>
      <div style="margin-top: 50px; width: 100%;">
        <div
          style="
            vertical-align: middle;
            border: 1px solid #000;
            width: 100%;
            font-size: 0;
          "
        >
          <img style="width:100%" :src="codeUrl" @click="changeCode" />
        </div>
      </div>
      <div
        style="
          margin-top: 20px;
          font-size: 14px;
          width: 100%;
          color: #bfbfbf;
          text-align: center;
        "
      >
        使用微信扫一扫
      </div>
      <div style="width: 100%; margin-top: 20px">
        <button
          @click="cancelBind"
          type="button"
          class="RegisterButton"
          style="
            width: 149px;
            margin-left: 20px;
            display: block;
            margin: 0 auto;
          "
        >
          取消
        </button>
        <!-- <button
          @click="close"
          type="button"
          class="RegisterButton"
          style="width: 100px; margin-left: 80px"
        >
          取消
        </button> -->
      </div>
    </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getCodeUrlUsing,
  getAuthorizedStatusUsing,
} from "@/api/authController";
export default {
  name: "BindWeChat",
  data() {
    return {
      codeUrl: "",
      dialogFormVisible: false,
      timer: -1,
      title: "",
      timerCode: -1,
    };
  },
  beforeDestroy() {
    window.clearTimeout(this.timer);
  },
  created() {},
  methods: {
    showEdit() {
      this.changeCode();
      this.dialogFormVisible = true;
      this.getBindWeChatStatus();
    },
    cancelBind() {
      this.$emit("cancelbind");
      this.close();
    },
    timeChangeCode() {
      window.clearTimeout(this.timerCode);
      this.timerCode = setTimeout(() => {
        this.changeCode();
        this.timeChangeCode();
      }, 110 * 1000);
    },
    async getBindWeChatStatus() {
      let that = this;
      window.clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        that.getAuthorizedStatus();
      }, 1000);
    },
    async getAuthorizedStatus() {
      getAuthorizedStatusUsing().then((res) => {
        if (res.code == 20000) {
          if (res.data.status == 6) {
            this.close();
            this.$baseMessage("绑定成功", "success", "vab-hey-message-success");
            this.$emit("bindSuccess");
            return;
          }
          this.getBindWeChatStatus();
        } else {
          this.$baseMessage(
            "修改失败,code:" + res.code + ",msg:" + res.msg,
            "error",
            "vab-hey-message-error"
          );
        }
      });
      // const { code, data } = await getAuthorizedStatusUsing();
      // if (code == 20000) {
      //   if (data.status == 5) {
      //     this.close();
      //     this.$baseMessage("绑定成功", "success", "vab-hey-message-success");
      //     this.$emit("bindSuccess");
      //     return;
      //   }
      //   this.getBindWeChatStatus();
      // }
    },
    close() {
      this.dialogFormVisible = false;
      window.clearTimeout(this.timerCode);
      window.clearTimeout(this.timer);
    },
    changeCode() {
      this.codeUrl = getCodeUrlUsing();
    },
  },
};
</script>

<style lang="scss">
.RegisterButton {
  background: #173d7c;
  border-radius: 10px;
  cursor: pointer;
  border: 0;
  font-size: 16px;
  color: #ffffff;
  margin-top: 15px;
  width: 149px;
  height: 30px;
  &:hover {
    background: #244f96;
  }
}
</style>
