<template>
  <div class="EduHome">
    <LoginHeader />
    <main class="page-container">
      <div class="login-mian">
        <div class="login-left">
          <!-- <div class="login-title">登录</div> -->
          <el-row class="login-qh">
            <div :class="{ on: tab == 1 }" @click="tabFn(1)">账号登录</div>
            <div :class="{ on: tab == 2 }" @click="tabFn(2)">微信登录</div>
          </el-row>
          <div v-if="tab == 1">
            <div class="login-input">
              <input
                class="phone"
                type="text"
                v-model="login.username"
                placeholder="请输入手机号码"
              />
            </div>
            <div class="login-input">
              <input
                class="password"
                type="password"
                v-model="login.password"
                placeholder="请输入密码"
              />
            </div>
            <div class="login-input">
              <input
                class="verify"
                type="text"
                v-model="login.captcha"
                placeholder="请输入验证码"
                @keyup.enter="loginFn"
              />
              <div class="verifyButton">
                <img class="code" :src="codeUrl" @click="changeCode" />
              </div>
            </div>
            <div class="login-botton">
              <el-button @click="loginFn" :loading="loading">提交</el-button>
            </div>
            <div class="loginRPwd">
              <!-- <label style="float: left"
                ><input
                  type="checkbox"
                  :checked="login.rememberMe"
                />记住我</label
              > -->
              <span style="float: right" @click="loginPwdFn">忘记密码？</span>
            </div>
            <div class="login-protocol">
              <div>
                <label><input v-model="checkedInfo"  type="checkbox"  /></label
                >同意<span
                  ><span @click="regPact">《注册协议》</span>&<span
                    @click="privacyPolicy"
                    >《隐私政策》</span
                  >
                </span>
              </div>
              <!-- <div>新版《用户协议》已经更新，请您及时查看</div> -->
            </div>
          </div>
          <div v-if="tab == 2">
            <div class="warBr">
              <img
                v-if="!isScanningRetry"
                :src="scanningCodeUrl"
                @click="scanningChangeCode"
              />
              <div
                v-else
                @click="refreshCodeUrl"
                style="width: 100%; height: 100%"
              >
                重试
              </div>
              <!-- <img  src="~@/assets/code.png" /> -->
            </div>
            <div class="warBr-title">使用微信扫一扫</div>
          </div>
        </div>
        <div class="login-right">
          <div class="login-right-title">向持续学习的人致敬！</div>
          <div class="login-right-button">
            <button @click="addUserFn">创建新用户</button>
          </div>
        </div>
      </div>
    </main>
    <!-- <footer class="LoginFooter">
      <div class="FooterCenter">
        <ul>
          <li>首页华路</li>
          <li>•</li>
          <li>国际名校</li>
          <li>•</li>
          <li>资讯中心</li>
          <li>•</li>
          <li>华路校友会</li>
          <li>•</li>
          <li>华路商学院简介</li>
          <li>•</li>
          <li>我要约课</li>
        </ul>
        <div class="FooterRight">
          <p>COPYRIGHT © 2009-2020 华路数字文化 版权所有</p>
          <p>www.cufehl.com 粤ICP备14094585号-1</p>
        </div>
      </div>
    </footer> -->
    <login-footer />
    <bind-we-chat
      ref="bindWeChat"
      @success="bindSuccess"
      @cancelbind="cancelBind"
    />
    <bind-we-chat-tip
      ref="bindWeChatTip"
      @bind="bind"
      @cancelbind="cancelBind"
    />
    <protocol-dialog ref="protocolDialog"></protocol-dialog>
  </div>
</template>

<script>
import LoginHeader from "@/components/LoginHeader";
import LoginFooter from "@/components/LoginFooter";
import { getImageCaptcha, loginInfo } from "@/api/authController";
import BindWeChat from "./components/BindWeChat.vue";
import BindWeChatTip from "./components/BindWeChatTip.vue";
import ProtocolDialog from "@/views/Login/components/ProtocolDialog.vue";
import { getCookiesByKey, removeookiesByKey } from "@/utils/auth.js";
import { getMemberProfile } from "@/api/memberController";
import {
  getCodeUrlUsing,
  getAuthorizedStatusUsing,
} from "@/api/authController";
import md5 from "js-md5";
export default {
  name: "Login",
  data() {
    return {
      tab: 1,
      login: {
        username: "",
        password: "",
        captcha: "",
        rememberMe: false,
      },
      codeUrl: "",
      scanningCodeUrl: "",
      loading: false,
      checkedInfo: true,
      redirect: undefined,
      timerCode: -1,
      timer: -1,
      isScanningRetry: false,
      dialogTableVisible: true,
    };
  },
  watch: {
    $route: {
      handler(route) {
        console.log("route", route);
        this.redirect = (route.query && route.query.redirect) || "/Login";
      },
      immediate: true,
    },
  },
  components: {
    LoginHeader,
    LoginFooter,
    BindWeChat,
    BindWeChatTip,
    ProtocolDialog,
  },
  created() {
    this.changeCode();
  },
  beforeDestroy() {
    window.clearTimeout(this.timerCode);
    window.clearTimeout(this.timer);
  },
  methods: {
    regPact() {
      this.$refs["protocolDialog"].showEdit("userReg");
    },
    privacyPolicy() {
      this.$refs["protocolDialog"].showEdit("privacyPolicy");
    },
    tabFn(row) {
      console.log(row);
      this.tab = row;
      if (row == 2) {
        this.scanningChangeCode();
        this.timeChangeCode();
        this.getScanningStatus();
      } else {
        window.clearTimeout(this.timerCode);
        window.clearTimeout(this.timer);
      }
    },
    async getScanningStatus() {
      let that = this;
      window.clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        that.getAuthorizedStatus();
      }, 1000);
    },
    refreshCodeUrl() {
      this.isScanningRetry = false;
      this.scanningChangeCode();
      this.timeChangeCode();
      this.getScanningStatus();
    },
    async getAuthorizedStatus() {
      getAuthorizedStatusUsing().then((res) => {
        if (res.code == 20000) {
          if (res.data.status == 6) {
            let data = res.data;
            this.$store.commit("set_token", data.session);
            this.goEduHome();
            return;
          } else if (res.data.status == 5) {
            this.isScanningRetry = true;
            this.$baseMessage(
              "您还未绑定微信，请先通过账户密码登陆后进行绑定,再尝试扫描登陆!",
              "warning",
              "vab-hey-message-warning"
            );
            removeookiesByKey("NO_MORE_REMINDERS");
            return;
          } else {
            if (res.data.status == -1) {
              // || res.data.status == 3
              this.isScanningRetry = true;
              this.$baseMessage(
                "服务器异常请稍后再试",
                "warning",
                "vab-hey-message-warning"
              );
              removeookiesByKey("NO_MORE_REMINDERS");
              return;
            }
          }
          this.getScanningStatus();
        } else {
          this.$baseMessage(
            "扫描登陆失败,code:" + res.code + ",msg:" + res.msg,
            "error",
            "vab-hey-message-error"
          );
        }
      });
    },
    scanningChangeCode() {
      this.scanningCodeUrl =
        getCodeUrlUsing() + "?timestamp=" + new Date().getTime();
    },
    timeChangeCode() {
      window.clearTimeout(this.timerCode);
      this.timerCode = setTimeout(() => {
        this.scanningChangeCode();
        this.timeChangeCode();
      }, 110 * 1000);
    },
    handleRoute() {
      return this.redirect === "/" ||
        this.redirect === "/Login" ||
        this.redirect === "/Register"
        ? "/home"
        : this.redirect;
    },
    async loginFn() {
      if (this.login.username == "") {
        this.$baseMessage("请填写用户名", "warning", "vab-hey-message-warning");
        return;
      }
      if (this.login.password == "") {
        this.$baseMessage("请填写密码", "warning", "vab-hey-message-warning");
        return;
      }
      if (this.login.captcha == "") {
        this.$baseMessage("请填写验证码", "warning", "vab-hey-message-warning");
        return;
      }
      if (!this.checkedInfo) {
        this.$baseMessage(
          "协议条款未填写",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      this.loading = true;
      const dataFrom = {
        username: this.login.username,
        captcha: this.login.captcha,
        password: md5(this.login.password),
      };
      this.loading = false;
      loginInfo(dataFrom).then((res) => {
        if (res.code != 20000) {
          this.changeCode();
          this.login.captcha = "";
          this.$baseMessage(res.msg, "error", "vab-hey-message-error");
        } else {
          let data = res.data;
          this.$store.commit("set_token", data.code);
          //获取用户收藏的题目
          this.$store.dispatch("searchAllTopicIdList");
          if (data.userInfo.openId != "" && data.userInfo.openId != null) {
            //表示已绑定
            this.goEduHome();
            return;
          }
          let value = getCookiesByKey("NO_MORE_REMINDERS");
          if (value == "true" && typeof value != "undefined") {
            this.goEduHome();
          } else {
            this.$refs["bindWeChatTip"].showEdit();
          }
        }
      });
    },
    async goEduHome() {
      const { code, data } = await getMemberProfile();
      if (code == 20000) {
        if (data.avatar || data.avatar != null) {
          sessionStorage.setItem("MEMBER_AVATAR", data.avatar);
        } else {
          sessionStorage.setItem("MEMBER_AVATAR", "");
        }
      }
      this.$router.push({
        path: this.handleRoute(),
      });
    },
    bind() {
      this.$refs["bindWeChat"].showEdit();
    },
    cancelBind() {
      this.bindSuccess();
    },
    bindSuccess() {
      this.goEduHome();
    },
    addUserFn() {
      console.log("创建新用户");
      this.$router.push({
        path: "/Register",
      });
    },
    loginPwdFn() {
      console.log("忘记密码");
      this.$router.push({
        path: "/ResetPassword",
      });
    },
    changeCode() {
      this.codeUrl = getImageCaptcha() + "?timestamp=" + new Date().getTime();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  flex: 1;
  padding: 0px 40px 42px;
  .login-mian {
    background: #ffffff;
    border: 1px solid #d8d8d8;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);

    border-radius: 0px;
    max-width: 1200px;
    margin: 0px auto;
    &::after {
      clear: both;
      display: block;
      content: "";
    }
    .login-left {
      position: relative;
      float: left;
      width: 50%;
      box-sizing: border-box;
      padding: 50px 100px;
      &::after {
        width: 1px;
        height: 300px;
        position: absolute;
        right: 0;
        top: 80px;
        display: block;
        content: "";
        background: #707070;
      }
      .login-title {
        font-size: 28px;
        text-align: center;
        color: #173d7c;
      }
      .login-input {
        margin-top: 25px;
        height: 45px;
        background: #ffffff;
        border: 1px solid #989898;
        overflow: hidden;
        border-radius: 0px;
        box-sizing: border-box;
        .phone {
          background: #ffffff url("../../assets/login/input-icon-1.png")
            no-repeat 0px center;
          background-size: 28px;
          height: 43px;
          width: 100%;
          box-sizing: border-box;
          border: 0;
          padding: 0 12px 0 34px;
        }
        .password {
          // background: #ffffff url("../../assets/login/input-icon-1.png")
          //   no-repeat 0px center;
          background: #ffffff url("../../assets/login/input-icon-2.png")
            no-repeat 0px center;
          background-size: 28px;
          height: 43px;
          width: 100%;
          box-sizing: border-box;
          border: 0;
          padding: 0 12px 0 34px;
        }
        .verify {
          // background: #ffffff url("../../assets/login/input-icon-2.png")
          //   no-repeat 0px center;
          background-size: 28px;
          height: 43px;
          width: 60%;
          box-sizing: border-box;
          border: 0;
          float: left;
          padding: 0 10px;
        }
        .verifyButton {
          float: right;
          // width: 93px;
          // height: 35px;
          // background: #efefef;

          // border-radius: 9px;
          // text-align: center;
          // line-height: 35px;
          // font-size: 12px;
          cursor: pointer;
          // color: #646464;
          margin-top: -4px;
          margin-right: 0px;
          // &:hover {
          //   background: #173d7c;
          //   color: #ffffff;
          // }
        }
      }
      .login-verify {
        height: 40px;
        background: #efefef;

        border-radius: 0px;
        margin-top: 25px;
      }
      .login-botton {
        margin-top: 30px;
        button {
          height: 50px;
          background: #173d7c;

          border-radius: 10px;
          cursor: pointer;
          border: 0;
          width: 100%;
          font-size: 16px;
          color: #ffffff;
          &:hover {
            background: #244f96;
          }
        }
      }
      .login-protocol {
        margin-top: 50px;
        text-align: center;
        font-size: 16px;
        color: #666666;
        line-height: 24px;
        span {
          color: #173d7c;
        }
      }
    }
    .login-right {
      float: right;
      width: 50%;
      box-sizing: border-box;
      padding: 50px 100px;
      .login-right-title {
        font-size: 48px;
        text-align: center;
        color: #818181;
        padding: 50px 60px 30px 60px;
        text-align: center;
      }
      .login-right-button {
        margin-top: 30px;
        button {
          height: 50px;
          background: #173d7c;

          border-radius: 10px;
          cursor: pointer;
          border: 0;
          width: 100%;
          font-size: 16px;
          color: #ffffff;
          &:hover {
            background: #244f96;
          }
        }
      }
    }
  }
}

.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.loginRPwd {
  text-align: right;
  margin-top: 10px;
  span {
    cursor: pointer;
    &:hover {
      color: #173d7c;
      text-decoration: underline;
    }
  }
}
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
.code {
  cursor: pointer;
  height: 45px;
  margin-top: 3px;
}
.login-qh {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  color: #888888;

  div {
    width: 50%;
    border-bottom: 2px solid #d1d1d1;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 60px;
    height: 60px;
    float: left;
  }
  .on {
    border-bottom: 2px solid #173d7c;
    font-weight: bold;
    color: #173d7c;
  }
}
.warBr {
  border: 1px solid #cccccc;
  padding: 0px;
  margin-top: 20px;
  text-align: center;
  img {
    width: 100%;
  }
}
.warBr-title {
  text-align: center;
  line-height: 36px;
}
</style>
