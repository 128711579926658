<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :show-close="false"
    width="28%"
    @close="close"
  >
    <div style="text-align:center">
      <div style="margin: 0 auto; display: inline-block; text-align:left; padding-bottom:5%;">
        <div style="font-size: 24px">是否绑定微信</div>
        <div style="font-size: 14px; margin-top: 10px; color: #bfbfbf">
          是否绑定微信,绑定后可快捷扫描登陆学习系统
        </div>
        <button @click="bindFn" type="button" class="RegisterButton">
          我要绑定
        </button>
        <div style="font-size: 16px; margin-top: 15px">
          <a
            href="javascript:(0)"
            @click="cancelBind"
            style="color: #bfbfbf; text-decoration: underline"
            >跳过</a
          >
          <a
            href="javascript:(0)"
            @click="setNoMoreReminders"
            style="color: #bfbfbf; margin-left: 20px; text-decoration: underline"
            >不再提醒</a
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { setCookiesByKey } from "@/utils/auth.js";
export default {
  name: "BindWeChatTip",
  data() {
    return {
      codeUrl: "",
      dialogFormVisible: false,
      timer: -1,
      title: "",
    };
  },
  created() {},
  methods: {
    showEdit() {
      this.dialogFormVisible = true;
    },
    setNoMoreReminders() {
      setCookiesByKey("NO_MORE_REMINDERS", true);
      this.cancelBind();
    },
    cancelBind(){
      this.$emit("cancelbind");
      this.close();
    },
    close() {
      this.dialogFormVisible = false;
    },
    bindFn() {
      this.close();
      this.$emit("bind");
    },
  },
};
</script>
<style lang="scss">
.RegisterButton {
  background: #173d7c;
  border-radius: 10px;
  cursor: pointer;
  border: 0;
  font-size: 16px;
  color: #ffffff;
  margin-top: 15px;
  width: 249px;
  height: 30px;

  &:hover {
    background: #244f96;
  }
}
</style>
